import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface ShareProps {
    service: string,
    url: string,
    title: string
}

@Dce("share")
export default class Share extends DceComponent<ShareProps> {

    private content: HTMLElement;

    constructor(elem: DceHTMLElement, props: ShareProps) {
        super(elem, props);

        elem.addEventListener('click', e => {

            let template = null;

            if(this.props.service == 'twitter'){
                template = 'https://twitter.com/intent/tweet?text={title}&url={url}';
            }

            if (this.props.service == 'ln') {
                //template = 'https://www.linkedin.com/shareArticle?mini=true&url={url}';
                template = 'https://www.linkedin.com/sharing/share-offsite/?url={url}';
            }

            if (this.props.service == 'fb') {
                template = 'https://www.facebook.com/sharer/sharer.php?t={title}&u={url}';
            }

            if(this.props.service == 'whatsapp'){
                template = 'https://api.whatsapp.com/send?text={title}%0a{url}';
            }


            if(null != template){

                var url = template.replace('{title}', encodeURIComponent(this.props.title))
                    .replace('{url}', encodeURIComponent(this.props.url));

                window.open(url,this.props.service + '-share-dialog',"width=626,height=436");
            }

        });

    }



}