import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface ShowMoreProps {
}

@Dce("showMore")
export default class ShowMore extends DceComponent<ShowMoreProps> {
    private text: HTMLElement;
    constructor(elem: DceHTMLElement, props: ShowMoreProps) {
        super(elem, props);

        this.text = elem.querySelector('[data-text]');

        elem.querySelectorAll('[data-more]').forEach(a => {

            a.addEventListener('click', e => {
                e.preventDefault();

                if(ShowMore.isVisible(this.text)){
                    this.text.style.display = 'none';
                    a.classList.remove('expanded-state');
                    a.innerHTML = a.getAttribute('data-more');
                }else{
                    this.text.style.display = 'block';
                    a.classList.add('expanded-state');
                    a.innerHTML = a.getAttribute('data-less');
                }

            });

        })

    }

    private static isVisible(elem: HTMLElement): boolean {
        return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );
    }

}