import DceHTMLElement from "../types/DceHTMLElement";
import DceElementExtention from "./DceElementExtention";

export default class DceComponent<T> {

    protected _props: T;

    protected elem: DceHTMLElement;

    constructor(elem: DceHTMLElement, props: T | null) {
        this.elem = elem;
        let dprops = this.defaultProps();
        if(null != dprops){
            this._props = {...dprops, ...props};
        }else{
            this._props = props;
        }
    }

    public dispatchSimpleEvent(eventType: string):void {
        setTimeout(() => {
            this.elem.ext.dispatchSimpleEvent(eventType);
        });
    }

    get ext(): DceElementExtention {
        return this.elem.ext;
    }

    get props(): T {
        return this._props;
    }

    public defaultProps(): Partial<T> {
        return null;
    }

    public onInit() {

    }

    public onRemove() {

    }

    public onSubComponentInit(component: string, elem: DceHTMLElement){
        elem.parentDceElement = this.elem;
    }

    public onSimpleEvent(event:string){
        
    }

}
