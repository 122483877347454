import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface ShowMoreProps {
}

@Dce("showNext")
export default class ShowMore extends DceComponent<ShowMoreProps> {
    private next: Element;
    constructor(elem: DceHTMLElement, props: ShowMoreProps) {
        super(elem, props);

        this.next = this.elem.nextElementSibling

        this.elem.addEventListener('click', e => {
            if(this.elem.classList.contains('expanded')){
                this.elem.classList.remove("expanded");
            }else{
                this.elem.classList.add("expanded");
            }

        });

    }

}