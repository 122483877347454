import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface OpenModalProps {
    modal: string
}

@Dce("openModal")
export default class Modal extends DceComponent<OpenModalProps> {

    private content: HTMLElement;

    constructor(elem: DceHTMLElement, props: OpenModalProps) {
        super(elem, props);

        elem.addEventListener('click', e => {

            document.querySelectorAll(this.props.modal).forEach(me => {
                document.body.style.paddingRight = this.scrollbarWidth() + 'px';
                document.body.classList.add('modal-opened');

                me.classList.add('opened');
            });

        })

    }

    private scrollbarWidth(): number {
        const documentWidth = document.documentElement.clientWidth;

        //console.log(documentWidth, window.innerWidth);

        return Math.abs(window.innerWidth - documentWidth);
    }

}