import LoggerInterface from "./LoggerInterface";

export default class NoConsoleLogger implements LoggerInterface {

    debug(msg: string, ...data: any[]): void {
    }

    log(msg: string, ...data: any[]): void {
    }

    info(msg: string, ...data: any[]): void {
    }

    warn(msg: string, ...data: any[]): void {
    }

    error(msg: string, ...data: any[]): void {
    }
}
