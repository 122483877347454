import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";

interface FiltersProps {
    url: string,
    target: string
}

@Dce("Filters")
export default class Filters extends DceComponent<FiltersProps> {

    private target: HTMLElement;

    private selected: Map<string, string[]> = new Map<string, string[]>();
    private checkedIds:string[] = [];

    private currentUrl = '';

    private loadState: any;

    constructor(elem: DceHTMLElement, props: FiltersProps) {
        super(elem, props);

        this.target = document.querySelector(this.props.target);
        console.log("AAAAAAAAAAAAAAAAAAAAAAAA");

        let change = (e: Event) => {
            let checkbox = e.target as HTMLInputElement;
            this.updateState(checkbox);
            this.makeRequest();
        }

        this.loadState = {
            'checked': []
        };
        elem.querySelectorAll('input[type="checkbox"]').forEach(v => {
            this.updateState(v as HTMLInputElement);
            (v as HTMLInputElement).addEventListener('change', change);

            if((v as HTMLInputElement).checked == true) {
                this.loadState.checked.push(v.id);
            }
        });


        console.log("XXXXXXXXXXXXXXXXX", this.loadState);

        window.addEventListener('popstate', e => {

            console.log("POP STATE", e.state);

            if(null == e.state){

                console.log("EMPTY POP STATE: ", this.loadState);

                let checked = this.loadState.checked;

                this.elem.querySelectorAll('input[type="checkbox"]').forEach(v => {

                    let checkbox = v as HTMLInputElement;

                    if(checked.indexOf(v.id) > -1){
                        checkbox.checked = true;
                    }else{
                        checkbox.checked = false;
                    }

                    this.updateState(checkbox);
                });

                this.makeRequest(false);
                return true;

            }else{
                if(e.state.hasOwnProperty('url') && e.state.url != this.currentUrl){

                    let checked: string[] = e.state.checked;

                    this.elem.querySelectorAll('input[type="checkbox"]').forEach(v => {

                        let checkbox = v as HTMLInputElement;

                        if(checked.indexOf(v.id) > -1){
                            checkbox.checked = true;
                        }else{
                            checkbox.checked = false;
                        }

                        this.updateState(checkbox);
                    });

                    this.makeRequest(false);
                    return true;
                }
            }



            return true;

        })
    }

    public updateState(checkbox: HTMLInputElement){

        const regex = /f\[([0-9]+)\]/gm;

        let m = regex.exec(checkbox.name);

        let name = m[1];
        let value: string = checkbox.value;

        if(checkbox.checked){
            if(!this.selected.has(name)){
                this.selected.set(name, []);
            }
            if(this.selected.get(name).indexOf(value) < 0) {
                this.selected.get(name).push(value);
            }

            let chi = this.checkedIds.indexOf(checkbox.id);
            if(chi == -1) {
                this.checkedIds.push(checkbox.id);
            }

        }else{
            if(this.selected.has(name)){
                let i = this.selected.get(name).indexOf(value);
                while(i >= 0){
                    this.selected.get(name).splice(i, 1);
                    i = this.selected.get(name).indexOf(value);
                }
            }

            let chi = this.checkedIds.indexOf(checkbox.id);
            if(chi != -1){
                this.checkedIds.splice(chi, 1);
            }

        }

    }



    private makeRequest(pushState: boolean = true){

        let param: string[] = [];

        for(let e of this.selected.entries()){

            if(e[1].length > 0){
                param.push(e[0] + ":" + e[1].join(","));
            }

        }

        console.log("PARAM: " + param.join(";"));

        let url = this.props.url;
        if(this.props.url.indexOf('?') > 0){
            url += '&';
        }else{
            url += '?';
        }

        url += 'fp=' + (param.join(";"));

        console.log(url);

        console.log(this.target);
        this.target.classList.add('loading');

        DceAjax.getInstance()
            .get(url)
            .then((r) => {

                if(pushState) {
                    history.pushState({
                        'url': url,
                        'checked': this.checkedIds
                    }, '', url);
                }

                let elem = document.createElement('div');
                elem.innerHTML = r.response.trim();

                let v = elem.firstElementChild;

                document.querySelector(this.props.target).replaceWith(v);

                this.target = v as HTMLElement;
                console.log("RESP" , r);
            });

    }
}