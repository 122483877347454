import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface ModalProps {

}

@Dce("modal")
export default class Modal extends DceComponent<ModalProps> {

    private content: HTMLElement;

    constructor(elem: DceHTMLElement, props: ModalProps) {
        super(elem, props);

        this.elem.querySelectorAll('.close').forEach(elem => {
            elem.addEventListener('click', e => this.close());
        })

        this.elem.addEventListener('click', e => {
            if(e.target == this.elem) {
                this.close();
            }
        })

        //console.log(location.hash);

        if(location.hash && location.hash.startsWith("#open-modal")){

            //console.log(location.hash);

            let openhash = location.hash.split(":");

            if(openhash.length > 1 && openhash[1] == this.elem.id){

                document.querySelectorAll('#' + this.elem.id).forEach(me => {
                    document.body.style.paddingRight = this.scrollbarWidth() + 'px';
                    document.body.classList.add('modal-opened');
                    me.classList.add('opened');
                });

                location.hash = "";
            }

        }

    }

    private close(){
        this.elem.classList.remove('opened');
        document.body.classList.remove('modal-opened');
        document.body.style.paddingRight = null;
    }

    private scrollbarWidth(): number {
        const documentWidth = document.documentElement.clientWidth;

        //console.log(documentWidth, window.innerWidth);

        return Math.abs(window.innerWidth - documentWidth);
    }
}