import DceEventDetails from "../types/DceEventDetails";
import DceHTMLElement from "../types/DceHTMLElement";

export default class DceEngineEvents {

    public static INIT_EVENT = 'init';
    public static REMOVE_EVENT = 'remove';
    public static SUBCOMP_INIT_EVENT = 'subcompInit';
    public static SUBCOMP_REMOVE_EVENT = 'subcompRemove';

    public static initEvent(name: string, elem: DceHTMLElement): CustomEvent<DceEventDetails> {
        return new CustomEvent<DceEventDetails>("dceEvent",
            {
                detail: {
                    type: this.INIT_EVENT,
                    component: name,
                    element: elem
                }
            }
        );
    }

    public static subComponentInit(name: string, elem: DceHTMLElement): CustomEvent<DceEventDetails> {
        return new CustomEvent<DceEventDetails>("dceEvent",
            {
                detail: {
                    type: this.SUBCOMP_INIT_EVENT,
                    component: name,
                    element: elem
                }
            }
        );
    }

    public static subComponentRemove(name: string, elem: DceHTMLElement): CustomEvent<DceEventDetails> {
        return new CustomEvent<DceEventDetails>("dceEvent",
            {
                detail: {
                    type: this.SUBCOMP_REMOVE_EVENT,
                    component: name,
                    element: elem
                }
            }
        );
    }

    public static removeEvent(name: string, elem: DceHTMLElement): CustomEvent<DceEventDetails> {
        return new CustomEvent<DceEventDetails>("dceEvent",
            {
                detail: {
                    type: this.REMOVE_EVENT,
                    component: name,
                    element: elem
                }
            }
        );
    }

}