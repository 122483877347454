import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction, DceAjaxResponse} from "dce-ajax";
import Axios, {CancelTokenSource, AxiosStatic} from "axios";


interface SearchAutocompleteProps {
    autocompleteUrl: string,
    searchUrl: string,

    autocompleteLoadingClass: string;
    autocompleteVisibleClass: string,
    inputFocusedClass: string
}

@Dce("SearchAutocomplete")
export default class SearchAutocomplete extends DceComponent<SearchAutocompleteProps> {

    private autocompleteUrl: string;
    private searchUrl: string;

    private reqCancelTokenSource: CancelTokenSource;

    private timeout: any;

    private autocompleteWrapper: HTMLElement;
    private form: HTMLFormElement;

    private input: HTMLInputElement;
    private categorySelect: HTMLSelectElement;

    private blurTimeout: any = false;
    private autocompleteList: HTMLElement;


    constructor(elem: DceHTMLElement, props: SearchAutocompleteProps) {
        super(elem, props);

        this.autocompleteUrl = props.autocompleteUrl;

        this.autocompleteWrapper = elem.querySelector('[data-role="autocomplete"]');
        this.autocompleteList = elem.querySelector('[data-role="autocomplete-list"]');

        this.input = elem.querySelector('input[type="text"]');
        this.categorySelect = elem.querySelector('select');
        this.form = elem.querySelector('form');

        console.log(this.input);
        console.log(this.categorySelect);

        this.input.addEventListener('focus', e => {
            if ((this.input as HTMLInputElement).value != '') {
                this.showAutocomplete();
            }

            this.elem.classList.add(this.props.inputFocusedClass);
        });

        this.input.addEventListener('blur', e => {
            this.blurTimeout = setTimeout(() => {
                this.hideAutocomplete();

                this.elem.classList.remove(this.props.inputFocusedClass);
            }, 200);


        });

        this.input.addEventListener('input', e => {
            let value = (e.target as HTMLInputElement).value;
            this.makeCall(value);
        });

        this.categorySelect.addEventListener('change', e => {
            let value = this.input.value;
            this.makeCall(value);
        });

        this.setupKeyboard();
    }

    public makeCall(value: string) {
        if (null != this.reqCancelTokenSource) {
            console.log('Canceling req');
            this.reqCancelTokenSource.cancel("Canceling request");
            this.reqCancelTokenSource = null;
        }

        if (null != this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        if (value != '' && value.length > 3) {

            console.log('value', value);

            this.showAutocomplete();

            this.elem.classList.add(this.props.autocompleteLoadingClass);

            this.timeout = setTimeout(() => {

                this.reqCancelTokenSource = DceAjax.cancelTokenSource();
                this.timeout = null;

                let data = new FormData();
                data.set('q', value);
                data.set('c', this.categorySelect.value);

                DceAjax.getInstance()
                    .post(this.autocompleteUrl, data, {
                        cancelToken: this.reqCancelTokenSource.token
                    }).then((e: DceAjaxResponse) => {
                        this.reqCancelTokenSource = null;
                        this.elem.classList.remove(this.props.autocompleteLoadingClass);

                        this.autocompleteList.innerHTML = e.response;
                        this.refreshkeyboardIndexes();
                        return e;
                    });
            }, 300);

        } else {
            this.autocompleteList.innerHTML = '';

            this.hideAutocomplete();
            this.refreshkeyboardIndexes();
        }
    }

    private showAutocomplete(){
        this.autocompleteWrapper.style.display = "block";
        this.elem.classList.add(this.props.autocompleteVisibleClass);
    }

    private hideAutocomplete() {
        this.autocompleteWrapper.style.display = null;

        this.elem.classList.remove(this.props.autocompleteLoadingClass)
        this.elem.classList.remove(this.props.autocompleteVisibleClass);
    }

    private selectedIndex: -1;
    private indexedLinks: HTMLElement[] = [];


    public setupKeyboard(){
        this.input.addEventListener('keydown', (e) => {

            if(e.key == 'ArrowUp'){
                if(this.selectedIndex < 0){
                    return;
                }

                this.selectedIndex -=1;
                this.updateSelected();
            }

            if(e.key == 'ArrowDown'){
                if(this.selectedIndex >= this.indexedLinks.length-1){
                    return;
                }
                this.selectedIndex +=1;
                this.updateSelected();
            }
        });

        this.form.addEventListener('submit', (e) => {

            if(this.selectedIndex < 0){
                return;
            }

            e.preventDefault();

            this.indexedLinks[this.selectedIndex].click();

        })
    }


    private searchTermLastUpdate: string = '';
    public updateSelected(){

        console.log(this.selectedIndex);

        for(let i= 0; i<this.indexedLinks.length; i++){

            if(i != this.selectedIndex){
                this.indexedLinks[i].classList.remove('selected');
            }else{
                this.indexedLinks[i].classList.add('selected');

                if(this.indexedLinks[i].dataset.hasOwnProperty('input')){
                    this.input.value = this.indexedLinks[i].dataset['input'];
                }else{
                    this.input.value = this.searchTermLastUpdate;
                }

            }

        }
    }

    public refreshkeyboardIndexes(){
        this.searchTermLastUpdate = this.input.value;
        this.indexedLinks = [];
        this.selectedIndex = -1;
        this.autocompleteWrapper.querySelectorAll('a').forEach(elem => {
            this.indexedLinks.push(elem);
        });
    }





    public term(e: Event, elem: HTMLElement) {

        if (null != this.blurTimeout) {
            clearTimeout(this.blurTimeout);
        }
        this.blurTimeout = null;

        let term = elem.dataset['term'];

        console.log("TTTTTTTT", term);

        if (term) {
            (this.input as HTMLInputElement).value = term;
            this.makeCall(term);
            this.input.focus();
        }


    }

}
