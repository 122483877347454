import LoggerInterface from "./LoggerInterface";

export default class ConsoleLogger implements LoggerInterface {

    private name: string;

    constructor(name: string) {
        this.name = name;
    }

    debug(msg: string, ...data: any[]): void {
        this.logMessage("debug", msg, data);
    }

    log(msg: string, ...data: any[]): void {
        this.logMessage("log", msg, data);
    }

    info(msg: string, ...data: any[]): void {
        this.logMessage("info", msg, data);
    }

    warn(msg: string, ...data: any[]): void {
        this.logMessage("warn", msg, data);
    }

    error(msg: string, ...data: any[]): void {
        this.logMessage("error", msg, data);
    }

    protected logMessage(type: "debug" | "log" | "info" | "warn" | "error", msg: string, data: any[]){

        data.unshift(msg);

        data.unshift(this.name);
        data.unshift("font-weight: 800; background-color: #efefef; color: #000");
        data.unshift("%c[%s]");

        console[type].apply(window, data);

    }
}