import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface CategoriesMenuProps {
    selectTabClass: string,
    selectContentClass: string
}

interface Tab {
    tab: HTMLElement,
    content: HTMLElement
}

@Dce("categoriesMenu")
export default class CategoriesMenu extends DceComponent<CategoriesMenuProps> {

    private text: HTMLElement;

    private tabs: Tab[] = [];

    private selected: Tab = null;

    constructor(elem: DceHTMLElement, props: CategoriesMenuProps) {
        super(elem, props);

        console.log(this.props)

        this.initialize();
    }

    private initialize(){

        let tabs = this.elem.querySelectorAll('[data-role="tab"]') as NodeListOf<HTMLElement>;
        let contents = this.elem.querySelectorAll('[data-role="content"]') as NodeListOf<HTMLElement>;

        tabs.forEach(tab => {
            let id = tab.dataset.id;

            let selected = false;

            console.log(tab, tab.classList);
            if (tab.classList.contains(this.props.selectTabClass)) {

                selected = true;
            }

            contents.forEach(c => {
                if ((c as HTMLElement).dataset.id == id) {
                    this.tabs.push({
                        tab: tab as HTMLElement,
                        content: c as HTMLElement
                    });

                    if (selected) {
                        this.selected = {
                            tab: tab as HTMLElement,
                            content: c as HTMLElement
                        }
                    }
                }
            });
        });

        console.log("SELECTED", this.selected);

        contents.forEach(c => {
            if(null != this.selected) {
                if (c.classList.contains(this.props.selectContentClass) && this.selected.content != c) {
                    c.classList.remove(this.props.selectContentClass);
                }
            }else{
                if (c.classList.contains(this.props.selectContentClass)) {
                    c.classList.remove(this.props.selectContentClass);
                }
            }
        });

        if(null != this.selected && !this.selected.content.classList.contains(this.props.selectContentClass)){
            this.selected.content.classList.add(this.props.selectContentClass);
        }

        tabs.forEach(tab => {
            tab.addEventListener('mouseover', e => {
                this.mouseOver(e);
            });
        });
    }

    private mouseOver(e: MouseEvent){

        console.log(e);
        console.log(e.target);

        let target = e.target as HTMLElement;


        if(null != this.selected){

            if(this.selected.tab == target || this.selected.tab.contains(target)){
                return;
            }

            this.selected.tab.classList.remove(this.props.selectTabClass);
            this.selected.content.classList.remove(this.props.selectContentClass);
        }


        for (const tab of this.tabs) {
            if(tab.tab == target || tab.tab.contains(target)){
                this.selected = tab;

                tab.tab.classList.add(this.props.selectTabClass);
                tab.content.classList.add(this.props.selectContentClass);
            }
        }

    }

}