import LoggerInterface from "./LoggerInterface";
import ConsoleLogger from "./ConsoleLogger";
import NoConsoleLogger from "./NoConsoleLogger";


export default class Logger {

    private static initialized:boolean = false;

    private static isConsoleAvailableVal: boolean = true;

    private static noConsoleLogger: LoggerInterface = null;

    public static of(name:string): LoggerInterface {

        if(Logger.isConsoleAvailable()){
            return new ConsoleLogger(name);
        }

        return Logger.noConsoleLogger;
    }

    private static isConsoleAvailable():boolean{
        if(!Logger.initialized){
            // @ts-ignore
            let isIE11 = !!window.MSInputMethodContext && !!document['documentMode'];

            if(isIE11){
                let cc = "" + console.log;
                Logger.isConsoleAvailableVal = cc.indexOf("__BR") > -1;
            }else {
                Logger.isConsoleAvailableVal = (typeof window.console !== "undefined" && window.console !== null && typeof window.console.log === "function");
            }

            if(!Logger.isConsoleAvailableVal){
                Logger.noConsoleLogger = new NoConsoleLogger();
            }

        }

        return Logger.isConsoleAvailableVal;
    }

}