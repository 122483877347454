import {DceComponent, DceHTMLElement} from "dce-engine";
import {DceAjax,  DceAjaxResponse} from "dce-ajax";
import Dce from "dce-engine/lib/decorators/Dce";

interface AjaxBlockProps {
}

@Dce("ajaxBlock")
export default class AjaxForm extends DceComponent<AjaxBlockProps>{

    private loading: boolean = false;

    constructor(elem: DceHTMLElement, props: AjaxBlockProps) {
        super(elem, props);

        this.elem.addEventListener('click', e => {

            if(this.loading){
                e.preventDefault();
                return false;
            }

            let target = e.target as HTMLElement;

            console.log('click', target);

            if(target.hasAttribute('data-ajax')){
                e.preventDefault();
                this.callClick(target);
            }else{
                let closest = target.closest('[data-ajax]');
                if(this.elem.contains(closest)){
                    target = closest as HTMLElement;
                    if(target.tagName == 'FORM'){
                        return ;
                    }
                    e.preventDefault();
                    return this.callClick(target);
                }
            }
        });

    }


    private callClick(sender: HTMLElement){
        console.log('call on', sender);
        this.loading = true;

        let loadingElem = sender;
        if(sender.hasAttribute('data-loader')){
            loadingElem = sender.closest(sender.getAttribute('data-loader'));

            if(!loadingElem){
                loadingElem = sender;
            }
        }

        loadingElem.classList.add("loading");

        DceAjax.getInstance()
            .get(sender.getAttribute('href'), {})
            .then(response => {
                this.loading = false;
                loadingElem.classList.remove("loading");

                setTimeout(() => {
                    this.processResponse(sender, response);
                })
            })
            .catch(reason => {
                this.loading = false;
                loadingElem.classList.remove("loading");
                console.log("LOCAL REJECT", reason);
            })

    }

    private processResponse(element: HTMLElement, response: DceAjaxResponse){
        if(response.isValid() && response.hasResponse()){
            let elem = this.createElementFromHTML(response.response);
            this.elem.replaceWith(elem);
        }
    }


    private createElementFromHTML(htmlString: string):Node {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstElementChild;
    }

}