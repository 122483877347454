import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface MobileMenuProps {
    menuRole: string;
    bodyClass: string;
}

@Dce("MobileMenu")
export default class MobileMenu extends DceComponent<MobileMenuProps>{

    private eventsAttached: boolean = false;

    private menu: HTMLElement;

    constructor(elem: DceHTMLElement, props: MobileMenuProps) {
        super(elem, props);

        this.elem.addEventListener('click', e => {

            if(!document.body.classList.contains(this.props.bodyClass)) {
                document.body.classList.add(this.props.bodyClass);
                if (!this.eventsAttached) {
                    this.attachMenuJsEvents();
                }
                setTimeout(() => this.menu.classList.add('c-menu-opened'), 10);
            }

        });

    }

    defaultProps(): MobileMenuProps {
        return {
            menuRole: 'mobile-menu',
            bodyClass: 'c-mobile-menu-opened'
        }
    }

    private attachMenuJsEvents() {

        this.eventsAttached = true;
        this.menu = document.querySelector('[data-role="' + this.props.menuRole + '"]');

        this.menu.querySelector('[data-role="mobile-menu-close"]')
            .addEventListener('click', e => {

                console.log("CLICK CLOSE");

                this.menu.classList.remove('c-menu-opened');
                setTimeout(() => document.body.classList.remove(this.props.bodyClass), 200);

            });


        this.menu.querySelectorAll('.c-menu__item.is-item-parent')
            .forEach((item0, i) => {

                if(item0.classList.contains('is-item-parent')) {

                    item0.addEventListener('click', (e) => {

                        if(!(e.target as HTMLElement).classList.contains('is-parent')){
                            return;
                        }

                        e.preventDefault();
                        e.stopPropagation();

                        if (!item0.classList.contains("c-menu__item-opened")) {
                            item0.classList.add("c-menu__item-opened");
                        } else {
                            item0.classList.remove("c-menu__item-opened");
                        }
                    });
                }


            })


    }

}

