import DceComponent from "../engine/DceComponent";
import type { DceComponentConstructor } from "../types/DceComponentConstructor";
import DceComponentsRegister from "../engine/DceComponentsRegister";


/**
 * Dekorator wskazujący na klasę komponentu
 * @param name nazwa komponentu do bindowania
 */
export default function Dce(name: string){
    return (constr: DceComponentConstructor<DceComponent<any>>) => {
        DceComponentsRegister.getInstance().add(name, constr);
    }
}