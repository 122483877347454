// add a registry of the type you expect
import DceComponent from "../engine/DceComponent";

import type {DceComponentConstructor} from "../types/DceComponentConstructor";
import {DcePluginConstructor} from "./types/DcePluginConstructor";
import DcePluginBase from "./types/DcePluginBase";
import DcePluginInterface from "./types/DcePluginInterface";
import DceHTMLElement from "../types/DceHTMLElement";

/**
 * Rejestr przechowujacy pluginy
 */
export default class DcePluginRegister {

    private static instance: DcePluginRegister;

    private map: Map<string, DcePluginInterface> = new Map();

    private plugins: DcePluginInterface[] = [];

    private constructor() {
    }

    static getInstance(): DcePluginRegister {
        if (!DcePluginRegister.instance) {
            DcePluginRegister.instance = new DcePluginRegister();
        }

        return DcePluginRegister.instance;
    }

    public add(name: string, constr: DcePluginConstructor<DcePluginInterface>) {
        console.log("plugin register called", name, constr);
        let plugin = new constr();
        this.map.set(name, plugin);
        this.plugins.push(plugin);
    }

    public getAll(): DcePluginBase[] {
        return this.plugins;
    }

    public initializeBody(body: HTMLElement) {
        this.plugins.forEach(plugin => {
            plugin.initializeBody(body);
        })
    }

    public addedNodes(nodes: Node[]) {
        this.plugins.forEach(plugin => {
            plugin.addedNodes(nodes);
        });
    }

    public mountedElementExtension(element: DceHTMLElement){
        this.plugins.forEach(plugin => {
            plugin.mountedElementExtension(element);
        });
    }
}
