import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface MenuScreensPros {
}

@Dce("menuScreens")
export default class MenuScreens extends DceComponent<MenuScreensPros> {

    private base: HTMLElement;

    private nextscreen: HTMLElement = null;

    constructor(elem: DceHTMLElement, props: MenuScreensPros) {
        super(elem, props);

        this.base = elem.querySelector('[data-role="base"]');

        elem.querySelectorAll('[data-next-screen]').forEach(btn => {

            let next = (btn as HTMLElement).dataset['nextScreen'];

            console.log(next);

            btn.addEventListener('click', e => {
                e.preventDefault();

                if(null != this.nextscreen){
                    this.nextscreen.remove();
                }

                let screen = this.elem.querySelector('div[data-screen="' + next + '"]');

                let clone = screen.cloneNode(true);
                this.base.after(clone);

                this.nextscreen = clone as HTMLElement;
                this.nextscreen.style.display = 'block';
                this.base.style.marginLeft = '-100%';

                (clone as HTMLElement).querySelector('[data-role="back"]').addEventListener('click', e => {

                    this.base.style.marginLeft = null;

                });

            });

        })

    }



}