import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface BodyClassToggleProps {
    clazz: string,

}

@Dce("bodyClassToggle")
export default class BodyClassToggle extends DceComponent<BodyClassToggleProps> {

    constructor(elem: DceHTMLElement, props: BodyClassToggleProps) {
        super(elem, props);

        this.elem.addEventListener('click', evt => {

            if(document.body.classList.contains(props.clazz)){
                document.body.classList.remove(props.clazz);
            }else{
                document.body.classList.add(props.clazz);
            }

        });

    }

}