import {DceComponent, DceHTMLElement} from "dce-engine";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import Dce from "dce-engine/lib/decorators/Dce";
import {Md5} from "ts-md5";

interface AjaxFormProps {
    closeModal?: boolean,
    buttonLoading?: boolean;
    bodyLoading?: boolean;
    catchSalt?: string
}

@Dce("ajaxForm")
export default class AjaxForm extends DceComponent<AjaxFormProps>{

    constructor(elem: DceHTMLElement, props: AjaxFormProps) {
        super(elem, props);

        let loaderDiv = document.createElement('div');
        loaderDiv.classList.add('loader');
        elem.append(loaderDiv);

        let button:HTMLElement = null;
        if(this.props.buttonLoading){
            button = this.elem.querySelector('button[type=submit]');
        }

        document.createElement('style');

        this.createCaptchaField();

        elem.addEventListener('submit', e => {
            e.preventDefault();

            if(elem.classList.contains('is-loading')){
                return;
            }

            let form = e.target as HTMLFormElement;

            elem.classList.add('is-loading');

            if(null != button){
                button.classList.add('is-loading');
            }

            let href = form.action;

            if(this.props.bodyLoading){
                document.body.classList.add('body-loading');
            }

            var formData = new FormData(form);

            DceAjax.getInstance()
                .post(href, formData)
                .then((r) => {

                    if(!r.isValid()){
                        form.replaceWith(
                            this.createElementFromHTML(r.response)
                        );
                    }else{

                        if(r.hasResponse()){
                            form.replaceWith(
                                this.createElementFromHTML(r.response)
                            );
                        }


                    }

                    if(null != button){
                        button.classList.remove('is-loading');
                    }

                    document.body.classList.remove('body-loading');
                    elem.classList.remove('is-loading')
                });
        });

    }


    private createCaptchaField(){
        //create style
        if(this.props.catchSalt) {
            let style = document.createElement('style');
            style.innerText = '.cap {position: fixed; top: -1000px; left: -1000px; opacity: 0; height:0; width:0; overflow: 0;}'

            document.head.append(style);

            let input = document.createElement('input');
            input.type="text";
            input.classList.add('cap');
            input.name = "_cap";
            input.id = "_cap";

            this.elem.prepend(input);

            let ecount: number = 0;

            let listener = (e: Event) => {
                ecount++;
                let val = ecount + "." + Math.floor(Math.random() * 1000000);
                val += '.' + Md5.hashStr(val);

                console.log(val);

                input.value = val;
            }

            this.elem.addEventListener('mouseover', listener);
            this.elem.addEventListener('keyup', listener);
            this.elem.addEventListener('touchstart', listener);
            this.elem.addEventListener('touchend', listener);
        }

    }

    private createElementFromHTML(htmlString: string):Node {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild;
    }

}