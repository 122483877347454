/**
 * Przechowuje odpowiedzi dla zapytań które są wywołane
 * z możliwościa przechowania odpowiedzi
 */
import {DceAjax, DceAjaxResponse} from "../index";
import {AxiosRequestConfig} from "axios";

class DceCacheGet {
    /**
     * Request state
     * 0 - added
     * 1 - pending
     * 2 - resolved
     * @private
     */
    private state: number = 0;
    private response: DceAjaxResponse = null;
    private promise: Promise<DceAjaxResponse>;

    public get(url: string, config?: AxiosRequestConfig) {

        if(this.state == 0){
            this.state = 1;
            this.promise = DceAjax.getInstance()
                .get(url, config)
                .then(resp => {
                    this.response = resp;
                    this.state = 2;
                    return resp;
                });
            return this.promise;
        }else if(this.state === 1){
            return this.promise;
        }else if(this.state === 2){
            return Promise.resolve(this.response);
        }

    }


}

export default class DceAjaxCache {

    private _map: Map<string, DceCacheGet> = new Map<string, DceCacheGet>();

    constructor() {
    }

    public get(url: string, config?: AxiosRequestConfig): Promise<DceAjaxResponse> {
        if(!this._map.has(url)){
            this._map.set(url, new DceCacheGet());
        }
        return this._map.get(url).get(url, config);
    }

}
