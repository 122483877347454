import {AxiosResponse} from "axios";

export default class DceAjaxResponse {

    protected status: number;

    protected _data: any;

    public static fromAxiosResponse(resp: AxiosResponse){
        return new DceAjaxResponse(resp.data, resp.status);
    }

    constructor(data: any, status: number) {
        this._data = data;
        this.status = status;
    }

    public rawData():any{
        return this._data;
    }

    public isValid():boolean{
        return this.status === 200;
    }

    public hasResponse():boolean {
        return this._data.hasOwnProperty('response');
    }

    get response():string {
        if(this._data.hasOwnProperty('response')){
            return this._data['response'] as string;
        }
        return null;
    }

    public hasDataPart(): boolean {
        return this._data.hasOwnProperty('data')
    }

    public hasData(key:string): boolean{
        return (this.hasDataPart() && this.data.hasOwnProperty(key));
    }

    public getData(key: string):any{
        if(this.hasData(key)){
            return this.data[key];
        }
        return null;
    }

    get data(): any{
        if(this.hasDataPart()){
            return this._data['data'] as any;
        }
        return {};
    }

    public getActions():Map<string, any>{
        let result:Map<string, any> = new Map()

        if(this._data.hasOwnProperty('actions')){
            Object.keys(this._data['actions']).forEach(key => {
                result.set(key, this._data['actions'][key]);
            });
        }

        return result;
    }

}